<template>
  <div class="gifs-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff;">
      <a-col :span="6">
        <h3 class="my-2" style="font-size: 20px;">Gifs</h3>
      </a-col>
      <a-col :span="18" class="text-right list-gifs" style="display: flex; justify-content: flex-end;">
        <a-radio-group default-value="-1" v-model="showGifsOnly" button-style="solid" size="large" @change="onShowActiveChange">
          <a-radio-button value="-1">
            All
          </a-radio-button>
          <a-radio-button value="1">
            Published
          </a-radio-button>
          <a-radio-button value="0">
            Unpublished
          </a-radio-button>
        </a-radio-group>
        <div class="list-gifs__row">
          <a-button class="ml-4" size="large" :disabled="!hasSelected" @click="onDeleteMultiple">
            <a-icon type="delete" /> Delete
          </a-button>
          <a-button class="ml-2" size="large" :disabled="!hasSelected" @click="onStatusMultiple(0)">
            <a-icon type="stop" /> Unpublish
          </a-button>
          <a-button type="primary" class="ml-4" size="large" @click="createGifModal">
            <a-icon type="plus" /> Add new
          </a-button>
        </div>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-collapse v-model="activeSection" expand-icon-position="left" @change="onCollapseChange">
        <a-collapse-panel key="waiting" header="Waiting" :forceRender=true>
          <div v-if="gifsLoading.waiting" class="text-center"><a-icon type="loading" class="font-size-24 text-gray-8"/></div>
          <a-checkbox-group v-model="selectedGifs.waiting" @change="onCheckedChange($event, 'waiting')">
          <draggable
            v-model="gifsData.waiting"
            v-bind="dragOptions"
            @start="drag = true"
            @end="sortGifs('waiting')"
            v-if="gifsData.waiting.length"
          >
            <transition-group tag="div" class="grid" type="transition" :name="!drag ? 'flip-grid' : null">
              <a-card v-for="(gif) in gifsData.waiting"
                      hoverable
                      :key="gif.id"
                      class="gif-card"
              >
                <div slot="cover">
                  <img
                    class="gif-card-cover-img"
                    @click="editGifModal(gif)"
                    :style="(activeHover !== gif.id && alwaysAnimated.waiting !== true) ? { background: 'url(' + gif.preview_path + ') center center' } : { background: 'url(' + gif.img_path + ') center center' }"
                    @mouseover="hoverOn(gif.id)"
                    @mouseleave="hoverOff"
                  />
                </div>
                <template slot="actions" class="ant-card-actions">
<!--                  <a-icon key="setting" type="setting" />-->
                  <span><a-checkbox :value="gif.id" name="gifChecked" /></span>
                  <span>
                    {{ gif.sharing_cnt }}
                  </span>
                  <span><a-switch v-model="gif.is_active" @change="onStatusSwitch($event, gif.id)" /></span>
<!--                  <a-icon key="ellipsis" type="ellipsis" />-->
                </template>
              </a-card>
            </transition-group>
          </draggable>
          </a-checkbox-group>
          <div slot="extra" @click.stop="">
            <a-checkbox v-model="alwaysAnimated.waiting">Show animated</a-checkbox>
            <a-checkbox :indeterminate="indeterminate.waiting" :checked="checkAll.waiting" @change="onCheckAllChange($event, 'waiting')">
            Select all
            </a-checkbox>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="win" header="Win">
          <div v-if="gifsLoading.win" class="text-center"><a-icon type="loading" class="font-size-24 text-gray-8"/></div>
          <a-checkbox-group v-model="selectedGifs.win" @change="onCheckedChange($event, 'win')">
            <draggable
              v-model="gifsData.win"
              v-bind="dragOptions"
              @start="drag = true"
              @end="sortGifs('win')"
              v-if="gifsData.win.length"
            >
              <transition-group tag="div" class="grid" type="transition" :name="!drag ? 'flip-grid' : null">
                <a-card v-for="(gif) in gifsData.win"
                        hoverable
                        :key="gif.id"
                        class="gif-card"
                >
                  <div slot="cover">
                    <img
                      class="gif-card-cover-img"
                      @click="editGifModal(gif)"
                      :style="(activeHover !== gif.id && alwaysAnimated.win !== true) ? { background: 'url(' + gif.preview_path + ') center center' } : { background: 'url(' + gif.img_path + ') center center' }"
                      @mouseover="hoverOn(gif.id)"
                      @mouseleave="hoverOff"
                    />
                  </div>
                  <template slot="actions" class="ant-card-actions">
                    <!--                  <a-icon key="setting" type="setting" />-->
                    <span><a-checkbox :value="gif.id" name="gifChecked" /></span>
                    <span>
                      {{ gif.sharing_cnt }}
                    </span>
                    <span><a-switch v-model="gif.is_active" @change="onStatusSwitch($event, gif.id)" /></span>
                    <!--                  <a-icon key="ellipsis" type="ellipsis" />-->
                  </template>
                </a-card>
              </transition-group>
            </draggable>
          </a-checkbox-group>
          <div slot="extra" @click.stop="">
            <a-checkbox v-model="alwaysAnimated.win">Show animated</a-checkbox>
            <a-checkbox :indeterminate="indeterminate.win" :checked="checkAll.win" @change="onCheckAllChange($event, 'win')">
              Select all
            </a-checkbox>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="fail" header="Fail">
          <div v-if="gifsLoading.fail" class="text-center"><a-icon type="loading" class="font-size-24 text-gray-8"/></div>
          <a-checkbox-group v-model="selectedGifs.fail" @change="onCheckedChange($event, 'fail')">
            <draggable
              v-model="gifsData.fail"
              v-bind="dragOptions"
              @start="drag = true"
              @end="sortGifs('fail')"
              v-if="gifsData.fail.length"
            >
              <transition-group tag="div" class="grid" type="transition" :name="!drag ? 'flip-grid' : null">
                <a-card v-for="(gif) in gifsData.fail"
                        hoverable
                        :key="gif.id"
                        class="gif-card"
                >
                  <div slot="cover">
                    <img
                      class="gif-card-cover-img"
                      @click="editGifModal(gif)"
                      :style="(activeHover !== gif.id && alwaysAnimated.fail !== true) ? { background: 'url(' + gif.preview_path + ') center center' } : { background: 'url(' + gif.img_path + ') center center' }"
                      @mouseover="hoverOn(gif.id)"
                      @mouseleave="hoverOff"
                    />
                  </div>
                  <template slot="actions" class="ant-card-actions">
                    <!--                  <a-icon key="setting" type="setting" />-->
                    <span><a-checkbox :value="gif.id" name="gifChecked" /></span>
                    <span>
                      {{ gif.sharing_cnt }}
                    </span>
                    <span><a-switch v-model="gif.is_active" @change="onStatusSwitch($event, gif.id)" /></span>
                    <!--                  <a-icon key="ellipsis" type="ellipsis" />-->
                  </template>
                </a-card>
              </transition-group>
            </draggable>
          </a-checkbox-group>
          <div slot="extra" @click.stop="">
            <a-checkbox v-model="alwaysAnimated.fail">Show animated</a-checkbox>
            <a-checkbox :indeterminate="indeterminate.fail" :checked="checkAll.fail" @change="onCheckAllChange($event, 'fail')">
              Select all
            </a-checkbox>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-modal
        v-model="gifModal"
        :title="gifObj && gifObj.id ? `Edit Gif [#${gifObj.id}]` : 'New Gif'"
        :footer="null"
        class="gif-modal"
        width="720px"
        :destroyOnClose="true"
      >
        <gif-modal
          @refreshGifs="getGifsAll"
          @createGif="handleCreateGif"
          @changeGif="handleUpdateGif"
          @deleteGif="handleDeleteGif"
          @closeModalFunction="gifModal = false"
          :active-obj="gifObj"
          ref="gifModal"
        />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import DEFAULT_GIF from './default_gif'
import { mapState } from 'vuex'
import gifModal from './gifModal.vue'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import draggable from 'vuedraggable'

export default {
  name: 'index',
  components: {
    gifModal,
    draggable,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    hasSelected() {
      return this.selectedGifs.waiting.length > 0 || this.selectedGifs.win.length > 0 || this.selectedGifs.fail.length > 0
    },
    selectedGifsAll() {
      return this.selectedGifs.waiting.concat(this.selectedGifs.win, this.selectedGifs.fail).sort((a, b) => a - b)
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  data() {
    return {
      accessModule: 'sports',
      showGifsOnly: '-1',
      gifsData: { waiting: [], win: [], fail: [] },
      gifsLoading: { waiting: false, win: false, fail: false },
      activeSection: ['waiting', 'win', 'fail'],
      selectedGifs: { waiting: [], win: [], fail: [] },
      indeterminate: { waiting: false, win: false, fail: false },
      checkAll: { waiting: false, win: false, fail: false },
      alwaysAnimated: { waiting: false, win: false, fail: false },
      drag: false,
      gifObj: undefined,
      searchValue: '',
      gifModal: false,
      activeHover: null,
    }
  },
  mounted () {
    this.getGifsAll()
  },
  created() {
    //
  },
  methods: {
    createGifModal() {
      this.gifObj = { ...DEFAULT_GIF }
      this.gifModal = true
    },
    editGifModal(obj) {
      this.gifObj = JSON.parse(JSON.stringify(obj))
      this.gifModal = true
    },
    handleUpdateGif(obj) {
      console.log('successfully updated. updating list...')
      this.gifModal = false
      this.getGifsAll()
      console.log(obj)
    },
    handleCreateGif(obj) {
      console.log('new gif successfully created. updating list...')
      this.gifModal = false
      this.getGifsAll()
      console.log(obj)
    },
    handleDeleteGif(obj) {
      const emotions = { 1: 'waiting', 2: 'win', 3: 'fail' }
      // if (obj.emotion_id === 1)
      this.gifsData[emotions[obj.emotion_id]].splice(this.gifsData[emotions[obj.emotion_id]].findIndex(x => x.id === obj.id), 1)
      // this.getGifsAll()
      this.gifModal = false
    },
    hoverOn(objId) {
      this.activeHover = objId
      console.log(this.activeHover, 'acrivehover')
    },
    hoverOff() {
      this.activeHover = null
    },
    onCheckedChange(checkedList, emotion) {
      this.indeterminate[emotion] = !!checkedList.length && checkedList.length < this.gifsData[emotion].length
      this.checkAll[emotion] = checkedList.length === this.gifsData[emotion].length
      this.selectedGifs[emotion].sort((a, b) => a - b)
      // console.log('checked list', checkedList)
      // console.log('checked list 2', this.selectedGifs[emotion])
      // console.log('selected from all', this.selectedGifsAll)
    },
    onCheckAllChange(e, emotion) {
      this.selectedGifs[emotion] = e.target.checked ? this.gifsData[emotion].map(record => record.id).sort((a, b) => a - b) : []
      this.indeterminate[emotion] = false
      this.checkAll[emotion] = e.target.checked
      console.log(`checked list ${emotion}`, this.selectedGifs[emotion])
    },
    onShowActiveChange(e) {
      console.log('show active change', e.target.value)
      this.gifsData = { waiting: [], win: [], fail: [] }
      this.getGifsAll()
    },
    onStatusSwitch(checked, objId) {
      console.log('checked' + objId, checked)
      console.log('checked' + objId, checked, 'J+OOOOOOOOOOOOOOOO')
      const url = `/admin/gifs/${objId}/status`
      apiClient.patch(url, {
        is_active: +checked,
      }).then((response) => {
        this.$notification.success({
          message: 'Active status changed',
          description: '',
        })
      }).catch(error => { console.log(error) })
    },
    onStatusMultiple(status) {
      const url = '/admin/gifs/status'
      const selectedData = { selected: this.selectedGifsAll, is_active: status }
      console.log(selectedData, 'selectedData')
      apiClient.patch(url, selectedData).then((response) => {
        this.$notification.success({
          message: 'Successfully unpublished',
          description: `${this.selectedGifsAll.length} gifs`,
        })
        this.selectedGifsAll = []
        this.getGifsAll()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while change status',
          description: error.message,
        })
      })
    },
    onCollapseChange(key) {
      console.log('active tab', key)
    },
    getGifs(emotion) {
      this.gifsLoading[emotion] = true
      const emotionIds = { waiting: 1, win: 2, fail: 3 }
      console.log(emotionIds[emotion])
      const sao = (this.showGifsOnly >= 0) ? `?active=${this.showGifsOnly}` : ''
      const url = '/admin/gifs/emotion/' + emotionIds[emotion] + sao
      apiClient.get(url).then((response) => {
        this.gifsData[emotion] = response.data.data
        console.log('gifsData', this.gifsData[emotion])
        this.gifsLoading[emotion] = false
      }).catch(error => { console.log(error); this.gifsLoading[emotion] = false })
    },
    getGifsWaiting() {
      this.getGifs('waiting')
    },
    getGifsWin() {
      this.getGifs('win')
    },
    getGifsFail() {
      this.getGifs('fail')
    },
    getGifsAll() {
      this.clearChecked()
      this.getGifsWaiting()
      this.getGifsWin()
      this.getGifsFail()
    },
    clearChecked() {
      this.selectedGifs = { waiting: [], win: [], fail: [] }
      this.indeterminate = { waiting: false, win: false, fail: false }
    },
    sortGifs(emotion) {
      const gifSort = this.gifsData[emotion].map((gif, index, gifArr) => {
        return {
          gif_id: gif.id,
          sort: gifArr.length - index,
        }
      })
      const sort = { sort: gifSort }
      const url = '/admin/gifs/sort/update'
      apiClient.patch(url, sort).then((response) => {
        console.log('sort success data', response.data.data)
      }).catch(error => { console.log(error) })
      this.drag = false
    },
    onDeleteMultiple() {
      const url = '/admin/gifs'
      const selectedData = { selected: this.selectedGifsAll }
      console.log(selectedData)
      apiClient.delete(url, { params: selectedData }).then((response) => {
        this.$notification.success({
          message: 'Successfully deleted',
          description: `${this.selectedGifsAll.length} gifs`,
        })
        this.selectedGifsAll = []
        this.getGifsAll()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting gifs',
          description: error.message,
        })
      })
    },
  },
}
</script>
<!--<style lang="scss" module>-->
<!--  @import './style.module.scss';-->
<!--</style>-->
<style lang="scss">
.gif-card {
  display: inline-block;
  margin: 6px !important;
  width: 200px;
  max-width: 200px;
  .gif-card-cover-img {
    width: 198px;
    height: 150px;
    max-width: 198px;
    max-height: 150px;
    background-size: cover !important;
    border: 0 !important;
  }
}
.list-gifs {
  @media(max-width: 1200px) {
    flex-direction: column-reverse;
    &__row {
      margin-bottom: 10px;
    }
  }
}
</style>
